import { useEffect, useState } from 'react';
import { IVideoResponse } from '../types/Video';
import VideoService from '../services/VideoService';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row, Typography } from 'antd';
import { extractVideoList, extractVideoList1, transformData } from '../utils';
import VideoHistoryService from '../services/VideoHistoryService';
import CourseService from '../services/CourseService';
import LearnSidebar from '../components/Learn/LearnSidebar';
import Notes from '../modules/Note/Notes';
import VimeoPlayer from '../components/VimeoPlayer';
import React from 'react';
import { transformDataNew } from '../transformData';

const { Title } = Typography;

const Learn = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [video, setVideo] = useState<IVideoResponse>();
    const [videoList, setVideoList] = useState<any[]>([]);
    const [watchedVideoList, setWatchedVideoList] = useState<any[]>([]);
    const { id, subjectId, courseId }: any = useParams();
    const [groups, setGroups] = useState<any[]>([]);
    const navigate = useNavigate();
    const [currentVideoIndex, setCurrentVideoIndex] = useState<number | null>(null);
    const [previousVideo, setPreviousVideo] = useState<any | null>(null);
    const [nextVideo, setNextVideo] = useState<any | null>(null);

    useEffect(() => {
        // Find the current video index based on the URL id
        const index = videoList.findIndex(video => video.id === parseInt(id));
        setCurrentVideoIndex(index);

        // Set previous and next videos based on the current index
        if (index !== -1) {
            setPreviousVideo(index > 0 ? videoList[index - 1] : null);
            setNextVideo(index < videoList.length - 1 ? videoList[index + 1] : null);
        }
    }, [id, videoList]);

    useEffect(() => {
        getVideo();
        getAllMeByCourse();
    }, [id]);

    const getAllMeByCourse = () => {
        setGroups([]);
        VideoHistoryService.getAllMeByCourse(courseId)
            .then((response: any) => {
                setWatchedVideoList(response.data);
                getCourseDetail(response.data);
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    const getCourseDetail = (videoList: any[]) => {
        setLoading(true);
        CourseService.getDetail(courseId)
            .then((response: any) => {
                const data = response.data;
                // Set groups data directly
                setGroups(response.data.groups);
                const allSubjects = response.data.groups.flatMap((group:any) => group.subjects);
                const subjectData = transformDataNew(allSubjects, watchedVideoList);
                console.log('subjectData', subjectData);
                const videos = extractVideoList1(subjectData);
                setVideoList(videos);
                setLoading(false);
            })
            .catch((e: Error) => {
                console.log(e);
                setLoading(false);
            });
    };

    const videHistoryPost = (videoId: any, subjectId: any, courseId: any, duration: any = 0) => {
        const postObj = {
            "subjectId": subjectId,
            "videoId": videoId,
            "courseId": courseId,
            "duration": parseInt(duration)
        };
        VideoHistoryService.create(postObj)
            .then((response: any) => {
                // Handle success
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    const getVideo = () => {
        setLoading(true);
        VideoService.get(id)
            .then((response: any) => {
                const data: IVideoResponse = response.data;
                setVideo(data);
                setLoading(false);
            })
            .catch((e: Error) => {
                console.log(e);
                setLoading(false);
            });
    };

    // Function to render subjects for each group
    const subjectRender = () => {
        return (
            <>
                {groups?.map((groupItem: any, index: number) => {
                    const transformedSubjects = transformDataNew(groupItem.subjects, watchedVideoList);
                    return (
                        <React.Fragment key={index}>
                            <Title level={3} style={{ paddingLeft: 16, marginTop: 0, padding: 16, color: '#fff', background: '#935ac0' }}>
                                {groupItem.name}
                            </Title>
                            <LearnSidebar subjects={transformedSubjects} />
                        </React.Fragment>
                    );
                })}
            </>
        );
    };

    return (
        <>
            <Row gutter={[24, 24]}>
            <Col xs={24} md={12} lg={16}>
                    <div style={{ position: 'sticky', top: '16px' }}>
                        <Card
                            loading={loading}
                            title={video?.title}
                            bodyStyle={{ paddingTop: 0 }}
                            extra={
                                <Row gutter={[16, 16]} justify={"space-between"}>
                                    <Col>
                                        {previousVideo && (
                                            <Link to={`/video/${previousVideo.id}/subject/${previousVideo.subjectId}/course/${courseId}/unit/${previousVideo.unitId}/lesson/${previousVideo.lessonId}`}>
                                                <Button disabled={loading} type="primary">Önceki Video</Button>
                                            </Link>
                                        )}
                                    </Col>
                                    <Col>
                                        {nextVideo && (
                                            <Link to={`/video/${nextVideo.id}/subject/${nextVideo.subjectId}/course/${courseId}/unit/${nextVideo.unitId}/lesson/${nextVideo.lessonId}`}>
                                                <Button disabled={loading} type="primary">Sonraki Video</Button>
                                            </Link>
                                        )}
                                    </Col>
                                </Row>
                            }
                        >
                            {video?.vimeoId !== '' && (
                                <>
                                    <Row>
                                        <Col span={24}>
                                            <VimeoPlayer videoId={video?.vimeoId} onTimeUpdate={(duration: any) => { videHistoryPost(video?.id, subjectId, courseId, duration) }} />
                                        </Col>
                                        <Col span={24}>
                                            <Notes entityId={video?.id} entityName='Video' />
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </Card>
                    </div>
                </Col>
                <Col xs={24} md={12} lg={8}>
                    <Card loading={groups.length === 0} bodyStyle={{ padding: '10px 0px' }}>
                        {subjectRender()}
                    </Card>
                </Col>
            </Row>
        </>
    );
}

export default Learn;
