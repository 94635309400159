import { http } from "../http-common";

const create = (data: any) => {
    return http.post<any>(`/chat/ask`, data);
};

const ChatService = {
    create
}

export default ChatService;